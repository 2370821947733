// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("./configurator/room_environment")
require("./configurator/rgbe_loader.js")
require("./custom_input_file")
require("./nested_forms/add_fields")
require("./nested_forms/remove_fields")
require("./utils")
require("./model_modal")
require("./model_form")
require("./model_show")
require("./model_configurator")

import 'popper.js'
import 'bootstrap'
import 'clipboard'

import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
