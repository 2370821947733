import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS

const PRINTING_LEVELS = 5;

const populateModelStructureModal = (elem, level = 0) => $('.modal-body').html(populateModelStructureModalHelper(elem, level));

const populateModelStructureModalHelper = (elem, level) => {
  if (!elem || nameMatch(elem.name, 'light')) return '';

  if (!elem.children || !elem.children.length || level === PRINTING_LEVELS) {
    return `<ul><li>${elem.name}</li></ul>`;
  }

  if (elem.name === '') return elem.children.map(e => populateModelStructureModalHelper(e, level + 1)).join('');

  const copyId = elem.name.match(/[0-9a-zA-Z_]*/gi).join('');
  return `
    <div class="accordion mb-2" id="accordion-${elem.name}">
      <div class="card">
        <div class="card-header" id="${elem.name}">
          <h2 class="mb-0 d-flex align-items-center">
            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse-${elem.name}" aria-expanded="true" aria-controls="collapse-${elem.name}">
              ${elem.name}
            </button>
            <i id="${copyId}" class="fa fa-clipboard btn" type="button" data-toggle="tooltip" data-placement="right" title="Copy to clipboard!"></i>
            <script>
              new ClipboardJS('#${copyId}', {
                text: () => "${elem.name}",
                container: document.getElementById('modelStructureModal')
              });
            </script>
          </h2>
        </div>

        <div id="collapse-${elem.name}" class="collapse" aria-labelledby="${elem.name}" data-parent="#accordion-${elem.name}">
          <div class="card-body">
            ${elem.children.map(e => populateModelStructureModalHelper(e, level + 1)).join('')}
          </div>
        </div>
      </div>
    </div>
  `;
};

$(document).on('turbolinks:load', () => {
  $("#model_glb").on("change", (event) => {
    const modelViewer = $("#model-viewer");
    modelViewer.removeClass("empty-model-viewer");
    modelViewer.attr("poster", modelViewer.data("poster-url"));
    modelViewer.attr("src", URL.createObjectURL(event.target.files[0]));
  });

  $("#model-screenshot").on("click", () => {
    $('model-viewer')[0].toBlob().then(blob => {
      const file = new File([blob], 'thumbnail');
      $("#thumbnailPreview").attr("src", URL.createObjectURL(file));
      const dt = new DataTransfer();
      dt.items.add(file)
      $("#model_thumbnail")[0].files = dt.files;
    });
  })

  const model = $("#model-viewer")[0];
  if (model) {
    model.addEventListener("load", (event) => {
      const loader = new THREE.GLTFLoader();
      loader.load(
        event.detail.url,
        gltf => populateModelStructureModal(gltf.scene),
        undefined,
        console.error
      );
    });
  }
})
