document.addEventListener('turbolinks:load', () =>{
  const fileInputs = document.getElementsByClassName("custom-file-input");

  for (fileInput of fileInputs) { 
    fileInput.addEventListener("change", (event) => {
      const newFile = event.target.files[0]
      event.target.nextElementSibling.textContent = newFile.name
    })
  }

  $('[data-toggle="tooltip"]').tooltip()

  $("form").on("submit", () => $("#loading_svg").toggleClass("d-none"));
})

