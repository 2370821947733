import QRCode from 'davidshimjs-qrcodejs';
import JSZip from 'jszip';
import ClipboardJS from 'clipboard';

// Extracted from https://dev.to/nombrekeff/download-file-from-blob-21ho
const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
}

const show = (e) => { e.removeClass("d-none"); e.addClass("d-flex"); };
const hide = (e) => { e.addClass("d-none"); e.removeClass("d-flex"); };
const firstSnippet = url => url;
const secondSnippet = url => `<iframe width="864" height="652" src="${url}" frameborder="0"></iframe>`;
const thirdSnippet = url =>
  "<div id='ex1' class='modal' style='width: 60vw; height: 90vh; padding: 0; max-width: unset;'>\n" +
  `  <iframe width='100%' height='100%' src='${url}' frameborder='0'></iframe>\n` +
  "</div>\n" +
  "<a style='text-decoration: none; color: white; background-color: #315f7a; border-color: #315f7a; display: inline-block; font-weight: 400; text-align: center; vertical-align: middle; border: 1px solid transparent; padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0.25rem; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;' href=#ex1 rel=modal:open>\n" +
    "  Open Modal\n" +
  "</a>\n" +
  "<script src='https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js'></script>\n" +
  "<script src='https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.js'></script>\n" +
  "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.css' />";


$(document).on('turbolinks:load', () => {
  const modelPreviewBody = $("#modal-model-preview");
  const modelConfiguratorBody = $("#modal-model-configurator");
  const codeSnippetsBody = $("#modal-code-snippets");

  let model;
  let modelModalBody;
  let modelModalHiddenBody;

  const modelToggleContentButton = $("#modalToggleContentButton");
  modelToggleContentButton.on("click", () => {
    modelModalBody.toggleClass("d-none");
    modelModalBody.toggleClass("d-flex");
    codeSnippetsBody.toggleClass("d-none");
    codeSnippetsBody.toggleClass("d-flex");
  });

  const modalDownloadContentButton = $('#modalDownloadContentButton');
  const loading = $("#loading_svg");

  modalDownloadContentButton.on("click", () => {
    const zip = new JSZip();
    const thumbnail = fetch(model.thumbnail).then(r => r.blob());
    const glb = fetch(model.glb).then(r => r.blob());
    const usdz = fetch(model.usdz).then(r => r.blob());
    modalDownloadContentButton.attr('disabled', true);
    loading.toggleClass("d-none");
    Promise.all([thumbnail, glb, usdz]).then(values => {
      zip.file(`${model.name}.png`, values[0]);
      zip.file(`${model.name}.glb`, values[1]);
      zip.file(`${model.name}.usdz`, values[2]);
      zip.generateAsync({type:"blob"}).then(content => downloadBlob(content, `${model.name}.zip`));
    }).finally(() => {
      modalDownloadContentButton.removeAttr('disabled');
      loading.toggleClass("d-none");
    });
  });

  $("#modelModal").on("show.bs.modal", (event) => {
    model = JSON.parse(event.relatedTarget.dataset["model"]);
    modelModalBody = model.configurator ? modelConfiguratorBody : modelPreviewBody;
    modelModalHiddenBody = model.configurator ? modelPreviewBody : modelConfiguratorBody;

    show(modelModalBody);
    hide(modelModalHiddenBody);
    hide(codeSnippetsBody);

    $("#modelModalTitle").text(model.name);

    const codeSnippets = $("code")
    const url = model.configurator ? model.configurator_url : model.url;
    codeSnippets[0].textContent = firstSnippet(url);
    codeSnippets[1].textContent = secondSnippet(url);
    codeSnippets[2].textContent = thirdSnippet(url);

    if (model.configurator) {
      modelConfiguratorBody.find('iframe').attr("src", `${url}?modal=true`);
    } else {
      $("#modalModelViewer").attr("ios-src", model.usdz);
      $("#modalModelViewer").attr("src", model.glb);
      $("#modelQRCode").empty();
      new QRCode(document.getElementById("modelQRCode"), { text: url, width: 200, height: 200 });
    }

    new ClipboardJS('#publicLinkSharing', {
      text: () => codeSnippets[0].textContent,
      container: document.getElementById('modelModal')
    });
    new ClipboardJS('#embedCodeSpinnerSharing', {
      text: () => codeSnippets[1].textContent,
      container: document.getElementById('modelModal')
    });
    new ClipboardJS('#embedCodeModalSharing', {
      text: () => codeSnippets[2].textContent,
      container: document.getElementById('modelModal')
    });
  })
});
