import QRCode from 'davidshimjs-qrcodejs'

$(document).on('turbolinks:load', () => {
  const modelViewer = $('#public-model-viewer');
  const modelShow = $('.model-show');
  const device = modelShow.data('device')

  const toggleElem = elem => {
    elem.toggleClass('d-flex');
    elem.toggleClass('d-none');
  }

  if (device && modelViewer[0]) {
    let href;

    if (device == 'ios') {
      href = modelViewer.attr('ios-src')
    } else {
      href = `intent://arvr.google.com/scene-viewer/1.0?file=${document.location.origin}${modelViewer.attr('src')}&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;end;`;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('rel', 'ar');
    anchor.appendChild(document.createElement('img')); // Necessary for usdz opening without navigating
    anchor.setAttribute('href', href);
    anchor.click();

  } else {
    const modelPoster = $('.mobile-poster');
    const modelQR = document.getElementById('modelQRCode');

    if (modelQR) {
      new QRCode(modelQR, { text: $('#modelQRCode').data('url'), width: 200, height: 200 })
    }

    toggleElem(modelShow);
    toggleElem(modelPoster);

  }
})
