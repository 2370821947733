
window.nameMatch = (name1 = '', name2 = '') => {
  if (name1.length === 0 || name2.length === 0) {
    return false;
  }

  const n1 = name1.toLowerCase().trim();
  const n2 = name2.toLowerCase().trim();
  const sanitizedN1 = n1.replaceAll('_', ' ').replace(/[(0-9)]/g, '').trim();
  const sanitizedN2 = n2.replaceAll('_', ' ').replace(/[(0-9)]/g, '').trim();
  return n1.includes(n2) || n2.includes(n1) ||
    sanitizedN1.includes(sanitizedN2) || sanitizedN2.includes(sanitizedN1);
};
