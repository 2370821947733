$(document).on('turbolinks:load', () => {
  
  const configurator = $('.configurator');

  if (configurator[0]) {
    const BACKGROUND_COLOR = 0xf1f1f1;

    // Init the scene
    const scene = new THREE.Scene();

    // Set background
    scene.background = new THREE.Color(BACKGROUND_COLOR );
    scene.fog = new THREE.Fog(BACKGROUND_COLOR, 20, 100);
    const canvas = document.querySelector('#c');

    // Init the renderer
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.shadowMap.enabled = true;
    renderer.gammaFactor = 2.2;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setPixelRatio(window.devicePixelRatio); 

    // Init the environment
    const rgbeLoader = new RGBELoader().setDataType(THREE.FloatType);
    rgbeLoader.load('/lightroom_14b.hdr', (texture) => {
      texture.mapping = THREE.EquirectangularRefractionMapping;
      scene.environment = texture;
    });

    document.body.appendChild(renderer.domElement);

    // Add a camera
    let camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;
    camera.position.y = 3;
    camera.position.x = 0;

    // Create the model
    const model = configurator.data('model');
    const path = model.path || configurator.data('path');
    const activeShapesByPartName = {};
    const shapes = {};
    const materials = {};
    model.parts.forEach(part => {
      part.shapes.forEach(shape => {
        shapes[shape.ref_name] = shape;
        shapes[shape.ref_name].name = part.name;
        shape.materials.forEach(material => {
          const materialKey = `${material.ref_name}_${shape.ref_name}`;
          materials[materialKey] = material;
          materials[materialKey].shape = shape;
        });
      });
    });

    const getMaterial = shape => {
      if (shape.children) {
        for (let i = 0; i < shape.children.length; i++) {
          if (nameMatch(shape.name, shape.children[i].name)) {
            return shape.children[i].material;
          }
        }
      }
      return shape.material;
    };

    const activateShape = (shape, shape3D) => {
      const partName = shape.name;
      activeShapesByPartName[partName] = shape;
      const currentMaterial = getMaterial(shape3D);
      const shapeSelect = document.getElementsByClassName(`shape-select-${partName}`)[0];
      const materialSelect = shapeSelect.parentElement.getElementsByClassName("material-select")[0];
      materialSelect.innerHTML = shape.materials.map(m => {
        const selected = currentMaterial && nameMatch(m.ref_name, currentMaterial.name);
        return `<option value='${m.ref_name}_${shape.ref_name}'${selected && 'selected=true'}>${m.name}</option>`;
      });
    };

    const loadingSpinner = $("#loading_svg");
    loadingSpinner.toggleClass("d-none");
    let loader = new THREE.GLTFLoader();
    let theModel;

    loader.load(path, (gltf) => {
      theModel = gltf.scene;

      theModel.traverse((o) => {
        if (o.isMesh) {
          o.castShadow = true;
          o.receiveShadow = true;
        }
        if (nameMatch(o.name, model.configurator_ref_name)) {
          o.traverse((shape) => {
            const currentShape = shapes[shape.name];
            if (currentShape) {
              if (!currentShape.default) {
                shape.visible = false;
              } else {
                activateShape(currentShape, shape)
              }
            }
          });
        } else if (nameMatch(o.name, 'materiales')) {
          o.visible = false;
        }
      });

      theModel.scale.set(2, 2, 2);
      theModel.rotation.y = Math.PI / 4;
      theModel.position.y = -1;

      scene.add(theModel);
      loadingSpinner.toggleClass("d-none");
    }, undefined, console.error);

    // Listener on shape changes
    const shapesInput = document.getElementsByClassName("shape-select");
    for (shapeInput of shapesInput) {
      shapeInput.addEventListener("change", (event) => {
        const shapeName = event.target.value;
        const partName = shapes[shapeName].name;
        const currentShape = activeShapesByPartName[partName];
        const newShape = shapes[shapeName];
        const newShapeObject = theModel.getObjectByName(newShape.ref_name);
        theModel.getObjectByName(currentShape.ref_name).visible = false;
        newShapeObject.visible = true;
        activateShape(newShape, newShapeObject);
      })
    }

    // Listener on material changes
    const materialsInput = document.getElementsByClassName("material-select");
    for (materialInput of materialsInput) {
      materialInput.addEventListener("change", (event) => {
        const materialref_name = event.target.value;
        const newMaterialMesh = theModel.getObjectByName(materials[materialref_name].ref_name);
        const currentShape = theModel.getObjectByName(materials[materialref_name].shape.ref_name);
        if (currentShape.isMesh) {
          currentShape.material = newMaterialMesh.material;
          currentShape.material.vertexColors = false;
        } else {
          currentShape.children.forEach(mesh => {
            if (nameMatch(mesh.name, currentShape.name)) {
              mesh.material = newMaterialMesh.material;
              mesh.material.vertexColors = false;
            }
          });
        }
      })
    }

    // Add lights
    const DIR_LIGHT_INTENSITY = 0.47;
    const DIR_LIGHT_Y = 8;
    const DIR_LIGHT_COORD = 8;

    let dirLight1 = new THREE.DirectionalLight(0xffffff, DIR_LIGHT_INTENSITY);
    dirLight1.position.set(-DIR_LIGHT_COORD, DIR_LIGHT_Y, DIR_LIGHT_COORD);
    scene.add(dirLight1);

    let dirLight2 = new THREE.DirectionalLight(0xffffff, DIR_LIGHT_INTENSITY);
    dirLight2.position.set(DIR_LIGHT_COORD, DIR_LIGHT_Y, DIR_LIGHT_COORD);
    scene.add(dirLight2);

    let dirLight3 = new THREE.DirectionalLight(0xffffff, DIR_LIGHT_INTENSITY);
    dirLight3.position.set(-DIR_LIGHT_COORD, DIR_LIGHT_Y, -DIR_LIGHT_COORD);
    scene.add(dirLight3);

    let dirLight4 = new THREE.DirectionalLight(0xffffff, DIR_LIGHT_INTENSITY);
    dirLight4.position.set(DIR_LIGHT_COORD, DIR_LIGHT_Y, -DIR_LIGHT_COORD);
    scene.add(dirLight4);

    // Floor
    let floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
    let floorMaterial = new THREE.MeshPhongMaterial({
      color: 0xeeeeee, // This color is manually dialed in to match the background color
      shininess: 0
    });

    let floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.rotation.x = -0.5 * Math.PI;
    floor.receiveShadow = true;
    floor.position.y = -1;
    scene.add(floor);

    // Add controls
    let controls = new THREE.OrbitControls(camera, renderer.domElement);
    controls.maxPolarAngle = Math.PI;
    controls.minPolarAngle = -Math.PI;
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.dampingFactor = 0.1;
    controls.autoRotate = false;
    controls.autoRotateSpeed = 0.2; // 30

    // Function - New resizing method
    const resizeRendererToDisplaySize = (renderer) => {
      const canvas = renderer.domElement;
      let width = window.innerWidth;
      let height = window.innerHeight;
      let canvasPixelWidth = canvas.width / window.devicePixelRatio;
      let canvasPixelHeight = canvas.height / window.devicePixelRatio;

      const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }

    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);

      if (resizeRendererToDisplaySize(renderer)) {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
      }
    }

    animate();

    // Disable scrolling
    window.onscroll = () => { window.scrollTo(0, 0); };
  }
});
